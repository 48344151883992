import request from './request';

/** 
 * 直播详情
 */
export function liveDetail(id, data) {
  return request.get(`/live/info/${id}`, data);
}

/**
  * @description 直播  登录socket
  */
 export function loginSocketApi(data) {
  return request.post(`live/socket/login`, data);
}

/**
  * @description 直播  加入房间
  */
 export function joinRoomApi(data) {
  return request.post(`live/socket/joinRoom`, data);
}

/**
  * @description 直播  退出房间
  */
 export function leaveGroup(data) {
  return request.post(`live/socket/leaveGroup`, data);
}

/**
  * @description 直播  发送消息
  */
 export function sendChatMsg(data) {
  return request.post(`live/sendChatMsg`, data);
}

/**
  * @description 直播  聊天记录
  */
 export function chatMsgList(data) {
  return request.get(`live/chatMsgList`, data);
}

/**
  * @description 直播  聊天记录
  */
 export function clickLike(data) {
  return request.post(`live/clickLike`, data);
}