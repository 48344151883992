<template>
  <div>
    <van-popup
      v-model:show="show"
      position="right"
      lock-scroll
      close-on-popstate
      :overlay-style="{ background: 'rgba(0, 0, 0, .7) url(https://artimg2.artart.cn/image/20220302/80677cce97eac55142063cc7bc9c117c.png) no-repeat 0.6rem center' }"
      :style="{ width: '85%', height: '100%' }"
    >
      <div class="auction-detail flex">
        <div class="all-t flex justify-c">
          <div class="fw500">出价记录</div>
        </div>
        <div class="auction-list">
          <div class="list-show">
            <van-list
              class="list"
              v-model="loading"
              :finished="finished"
              :immediate-check="false"
              finished-text="我也是有底线的"
              @load="loadList"
            >
              <template
                v-for="(item, index) in list"
                :key="index"
              >
                <div class="cj-item flex ft24 align-items-c">
                  <div class="l">
                    <div
                      class="fw500"
                      :class="{ 'my': item.uid == uid }"
                    >{{item.uid == uid ? '我的出价' : item.user.nickname}} <text
                        class="ft20 colorGray"
                        style="font-weight: 400;"
                        v-if="item.is_proxy_bid == 1"
                      >(委托)</text></div>
                    <div class="ft20 color-gray">{{item.create_time}}</div>
                  </div>
                  <div class="c flex-one ft20 colorGray flex align-items-c">
                    <div style="position: relative;">{{ merchantInfo.fee_type == 'CNY' ? 'RMB' : merchantInfo.fee_type }} <text
                        class="ft500 ft24"
                        :style="{ color: (index == 0 ? '#B31E24' : '#000') }"
                      >{{formatPriceNumber(item.bid_price, 2)}}</text>
                      <img
                        class="bid-img1"
                        src="https://artimg2.artart.cn/image/20211228/7a6465680df68000458b901e9e77d137.png"
                        v-if="item.is_confirm == 0"
                      >
                    </div>
                  </div>
                  <div class="r">
                    <img
                      v-if="index == 0"
                      src="https://artimg2.artart.cn/image/20211224/1f25762ae0a20274e282f25675450d22.png"
                    >
                    <img
                      v-else
                      src="https://artimg2.artart.cn/image/20211224/61165fe510b3ed5bd38c411ee2c7646e.png"
                    >
                  </div>
                </div>
              </template>
            </van-list>
          </div>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { Popup, Toast } from "vant";
import { useStore } from 'vuex';
import { ref, computed } from "vue";
import { formatPriceNumber } from "@/utils/util";
import { bidRecordList } from '@/api/auction';

export default {
  components: {
    [Popup.name]: Popup,
  },
  props: {
    merchant: Object,
  },
  setup(props) {
    const store = useStore();
    const show = ref(false);

    // 获取用户可出价额度
    const uid = computed(() => {
      return store.getters.uid;
    });
    const loading = ref(false);
    const pageLoading = ref(false);
    const finished = ref(false);
    const list = ref([]); // 出价记录列表

    const queryParams = ref({
      page: 1,
      limit: 20,
    });
    const total = ref(0);

    const loadList = () => {
      if (finished.value) return false;
      if (pageLoading.value) return false;
      pageLoading.value = true;
      loading.value = true;
      bidRecordList(queryParams.value)
        .then((res) => {
          total.value = res.data.count;
          if (queryParams.value.page === 1) {
            list.value = res.data.list;
          } else {
            list.value = list.value.concat(res.data.list);
          }
          if (list.value.length >= res.data.count) {
            finished.value = true;
          }
          queryParams.value.page += 1;
          pageLoading.value = false;
          loading.value = false;
        })
        .catch((message) => Toast(message));
    };

    const merchantInfo = computed(() => {
      if(props.merchant) {
        return props.merchant;
      }
      return store.getters.merchantInfo ? store.getters.merchantInfo : {};
    });

    return {
      show,
      uid,
      loading,
      finished,
      queryParams,
      list,
      total,
      merchantInfo,

      loadList,
      formatPriceNumber,
    };
  },
};
</script>

<style lang="scss" scoped>
.auction-detail {
  flex-direction: column;
  height: 100%;
}
.all-t {
  padding: 32px;
}

.all-t div {
  font-size: 32px;
}

.auction-list {
  flex: 1;
  overflow: hidden;
}

.auction-list .list-show {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.auction-list .list {
  width: 100%;
  height: 100%;
}

.cj-item {
  height: 100px;
  border-top: 2px solid #eee;
  margin: 0 32px;
}

.cj-item image {
  width: 48px;
  height: 48px;
  margin-left: 20px;
}

.cj-item .l {
  width: 280px;
}

.cj-item .l .my {
  color: #c1955c;
}

.cj-item .bid-img1 {
  width: 68px;
  height: 34px;
  position: absolute;
  right: 0;
  top: -30px;
}
</style>
