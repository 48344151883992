<template>
  <div class="home">
    <div class="live-title flex align-items-c">
      <div
        class="back-img item-flex-center"
        @click="backAction"
      >
        <img
          src="https://artimg2.artart.cn/image/20220302/83b12da0d968fcb49a28b3b7afe006fe.png"
          alt=""
        >
      </div>
      <div class="title-right flex-one flex align-items-c">
        <div class="live-name line1 ft14 fw500 flex-one">{{ mer_name }}</div>
        <div class="live-number color-gray mr20"><span>{{ liveData.click_num }}看过</span></div>
      </div>
    </div>
    <div class="live">
      <div
        v-if="liveData.live_status == 1"
        class="txt"
      >主播还未开始直播</div>
      <div
        v-else-if="liveData.live_status == 3"
        class="txt"
      >稍后查看回放</div>
      <div
        v-else-if="liveStatue == -1"
        class="txt"
      >主播暂时离开，请稍后</div>
      <template v-else-if="liveData.live_status == 2">
        <div
          id="id_test_video"
          ref="tcplayer"
        ></div>
        <div
          class="play-icon"
          @click="playAction"
          v-if="liveStatue == 0"
        >
          <img src="https://img.artart.cn/node/2020/38/1583600791211370936.png">
        </div>
        <div class="live-dialog flex">
          <div class="fw500 ft11">图文</div>
        </div>
      </template>
    </div>
    <SynchronousAuction ref="synchronous" />
  </div>
</template>

<script>
import SynchronousAuction from "./modules/SynchronousAuction";
import { useStore } from "vuex";
import { liveDetail, loginSocketApi, joinRoomApi } from "@/api/live";
import { ref, onBeforeMount, reactive, computed, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { TcPlayer } from "../../assets/js/TcPlayer-module-2.4.1";
import { Toast } from "vant";
import webSocket from "@/utils/webSocket";

export default {
  components: { SynchronousAuction },
  setup() {
    const router = useRouter();
    const store = useStore();
    const route = useRoute();
    const id = ref(""); // 直播id
    const albumId = ref(""); // 拍卖专场id
    const synchronous = ref(null);
    const liveData = ref({});
    const liveStatue = ref(0);
    const sdata = reactive({
      socketServe: webSocket.Instance,
    });
    const client_id = ref(""); // socket连接标识
    // 获取用户可出价额度
    const mer_name = computed(() => {
      return store.getters.mer_name;
    });

    onBeforeMount(() => {
      store.dispatch("user/getInfo");
      store.dispatch("user/getAccountInfo");
      id.value = route.query.id;
      nextTick(() => {
        liveDetail(id.value).then((res) => {
          liveData.value = res.data;
          // 赋值给组件

          synchronous.value.roomId = id.value;
          synchronous.value.albumId = res.data.relation_id;
          synchronous.value.loadNowAuction();
          synchronous.value.loadAlbumDetail();

          liveStatue.value = res.data.stream_state == 1 ? 0 : -1;
          if (liveData.value.live_status == 2) {
            if (res.data.stream_state == 1) {
              setTimeout(() => {
                playAction();
              }, 100);
            }
          }
        });

        if (!webSocket.Instance.ws) {
          webSocket.Instance.connect();
          sdata.socketServe = webSocket.Instance;
          sdata.socketServe.registerCallBack("getMessage", (data) => {
            sockedMes(data);
          });
          sdata.socketServe.registerCallBack("openSocket", () => {
            // sendData({
            //   type: "join_auction_room",
            //   auction_id: id.value,
            // });
          });
        } else if (client_id.value || webSocket.Instance.client_id) {
          sdata.socketServe.registerCallBack("getMessage", (data) => {
            sockedMes(data);
          });
          joinRoom();
          // sendData({
          //   type: "join_auction_room",
          //   auction_id: id.value,
          // });
        }
      });
    });

    const sockedMes = (d) => {
      if (d.type == "init") {
        client_id.value = d.client_id;
        webSocket.Instance.client_id = d.client_id;
        loginSocket();
      } else if (d.type == "stream_state") {
        if (d.group_id == id.value) {
          if (d.data.state === 1) {
            if (liveData.value.live_status == 2) {
              playAction();
            }
          } else {
            if (player && player.playing()) {
              player.pause();
              isPlay.value = false;
              return false;
            }
            liveStatue.value = -1;
          }
        }
      } else {
        if (synchronous.value) {
          synchronous.value.sockedMes(d);
        }
      }
    };

    const loginSocket = () => {
      loginSocketApi({
        client_id: client_id.value,
      })
        .then(() => {
          joinRoom();
        })
        .catch((message) => Toast(message));
    };

    const joinRoom = () => {
      joinRoomApi({
        client_id: client_id.value || webSocket.Instance.client_id,
        room_type: "live",
        room_id: id.value,
      })
        .then((res) => {})
        .catch((message) => Toast(message));
    };

    let player = null;
    const isPlay = ref(false);

    const loadVideo = () => {
      const w = document.documentElement.clientWidth;
      player = new TcPlayer("id_test_video", {
        webrtc: liveData.value.play_url.webrtc,
        m3u8: liveData.value.play_url.hls,
        flv: liveData.value.play_url.flv,
        autoplay: true, //iOS 下 safari 浏览器，以及大部分移动端浏览器是不开放视频自动播放这个能力的
        poster: {
          style: "cover",
          src: liveData.value.cover_image,
        },
        width: w,
        height: (w / 16) * 9,
        live: true,
        controls: "none",
        playbackRates: [0.5, 1, 1.25, 1.5, 2],
        listener: (d) => {
          if (d.type == "loadeddata") {
            const video = document.getElementsByTagName("video");
            if (video && video[0]) {
              video[0].style.objectFit = "cover";
            }
          } else if (d.type == "error") {
            liveStatue.value = -1;
          } else if (d.type == "play") {
            liveStatue.value = 1;
            isPlay.value = true;
          }
        },
      });
    };
    const playAction = () => {
      if (player) {
        player.play();
        isPlay.value = true;
        return false;
      }
      liveStatue.value = 0;
      setTimeout(() => {
        loadVideo();
      }, 100);
    };

    const backAction = () => {
      if (window.history.length <= 1) {
        router.replace("/");
      } else {
        router.back();
      }
    };

    return {
      liveData,
      isPlay,
      liveStatue,
      albumId,
      synchronous,
      mer_name,

      playAction,
      backAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.home {
  padding: 0;
  overflow: hidden;

  .live-title {
    height: 88px;
    background: #fff;

    .back-img {
      margin-left: 32px;
      height: 88px;

      img {
        width: 48px;
        height: 48px;
        display: block;
      }
    }

    .title-right {
      overflow: hidden;

      .live-number {
        font-size: 20px;
      }
    }
  }

  .live {
    height: 422.25px;
    background: #000;
    position: relative;

    .txt {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 26px;
      font-weight: 500;
      color: #fff;
    }

    .live-dialog {
      position: absolute;
      right: 24px;
      bottom: 18px;
      z-index: 100;

      div {
        margin-left: 8px;
        width: 76px;
        height: 40px;
        background: rgba(0, 0, 0, 0.4);
        border-radius: 20px;
        line-height: 40px;
        text-align: center;
        color: #fff;
      }
    }
  }

  .ft11 {
    font-size: 22px;
  }
}

.play-icon {
  position: absolute;
  margin-left: -35px;
  margin-top: -35px;
  left: 50%;
  top: 50%;
  width: 150px;
  height: 150px;
  z-index: 1000;
}

.play-icon image {
  width: 150px;
  height: 150px;
  display: block;
}
</style>
