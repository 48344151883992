<template>
  <div v-if="auctionDetail && albumData">
    <template v-if="status == 1 || status == 2">
      <div class="auction-view">
        <div
          class="auction-title padding flex justify-b"
          style="position: relative;overflow: hidden;"
        >
          <div class="ft14 fw500">第{{ auctionDetail.order_num }}件</div>
          <div
            class="flex align-items-c"
            @click="showListAction"
          >
            <div class="ft12">本场共{{ albumData.auction_num }}件拍品</div>
            <img src="https://artimg2.artart.cn/image/20211224/135445d7c1001ac3c8e122d1b50ddfd5.png">
          </div>
          <div
            class="notice flex align-items-c"
            :class="{ 'show': systemList.length > 0 }"
          >
            <img src="https://artimg2.artart.cn/image/20211225/7cdf3c327a40c6f2d24760ab0504efea.png">
            <div class="flex-one ft12">{{systemList[0]}}</div>
          </div>
        </div>
        <div
          class="auction-title line1 fw500"
          @click="showDetailAction"
        >LOT{{ auctionDetail.lot }} {{ auctionDetail.product ? auctionDetail.product.store_name : '' }}</div>

        <div class="auction-content flex justify-b">
          <div
            class="auction-img"
            @click="showDetailAction"
          >
            <img
              v-if="auctionDetail.product"
              :src="auctionDetail.product.image + '!450a'"
            >
            <div class="auction-img-txt flex align-items-c justify-c">
              <div
                class="ft24 colorWrite"
                style="font-weight: 300;"
              >详情</div>
            </div>
          </div>
          <div class="auction-price">
            <div
              class="auction-price-top"
              style="position: relative;"
            >
              <div class="t flex justify-b align-items-c">
                <div class="ft28 fw500 tl">{{ cjList[0] ? '当前价' : '起拍价'}}<span class="ft20">{{ merchantInfo.fee_type == 'CNY' ? 'RMB' : merchantInfo.fee_type }}</span></div>
                <div class="ft20 fw500 tr">
                  <template v-if="cjList[0]">
                    <template v-if="cjList[0].is_confirm == 0">出价待确认</template>
                    <template v-else-if="cjList[0].is_confirm == 1"><span style="color: #C1955C;">出价已确认</span></template>
                  </template>
                </div>
              </div>
              <div class="c">
                <!-- <div>{{cjList[0] ? formatPriceNumber(cjList[0].bid_price, 2) : auctionDetail.start_price}}</div> -->
                <van-swipe
                  ref="vanSwiper"
                  class="bid-price-list1"
                  :duration="200"
                  :loop="false"
                  :show-indicators="false"
                  :vertical="true"
                  :touchable="false"
                  :stop-propagation="false"
                >
                  <template v-if="cjList1 && cjList1.length > 0">
                    <van-swipe-item
                      v-for="(m, i) in cjList1"
                      :key="`bid${i}`"
                    >
                      <div class="bid-p">{{ formatPriceNumber(m.bid_price, 2) }}</div>
                    </van-swipe-item>
                  </template>
                  <template v-else>
                    <div class="bid-p">{{ auctionDetail.start_price ? formatPriceNumber(auctionDetail.start_price, 2) : '无底价' }}</div>
                  </template>
                </van-swipe>
              </div>
              <div class="b flex align-items-c">
                <template v-if="cjList[0]">
                  <img
                    class="b-image"
                    src="https://artimg2.artart.cn/image/20211224/1f25762ae0a20274e282f25675450d22.png"
                  >
                  <div :class="{'is-my': cjList[0].uid == uid }">
                    <template v-if="cjList[0].uid == uid">我的出价</template>
                    <template v-else-if="cjList[0].uid == 8888">现场出价</template>
                    <template v-else>{{ cjList[0].user.number.number }}</template>
                  </div>
                </template>
                <template v-else>
                  <div>等待出价...</div>
                </template>
              </div>
              <div
                @touchmove.stop="kong"
                @click="showRecodeAction"
                class="zhanwei"
              ></div>
            </div>
            <div class="auction-price-bottom flex align-items-c">
              <div class="c flex-one flex align-items-c justify-b">
                <div class="c-l flex align-items-c">
                  <template v-if="cjList[1]">
                    <img
                      class="c-image"
                      src="https://artimg2.artart.cn/image/20211224/61165fe510b3ed5bd38c411ee2c7646e.png"
                    >
                    <div
                      class="ft20 fw500 colorGray"
                      style="margin-left: 4px;"
                    >
                      <template v-if="cjList[1].uid == uid">我的出价</template>
                      <template v-else-if="cjList[1].uid == 8888">现场出价</template>
                      <template v-else>{{ cjList[1].user.number.number }}</template>
                    </div>
                  </template>
                  <template v-else>
                    <img
                      class="c-zw-image"
                      src="https://artimg2.artart.cn/image/20211225/7c391e872c36436b388567d37a8c6b69.png"
                    >
                  </template>
                </div>
                <div
                  class="c-r ft24 fw500"
                  v-if="cjList[1]"
                >{{ formatPriceNumber(cjList[1].bid_price, 2)
                }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else-if="status == 3">
      <div class="album-end">
        <div class="album-end-border">
          <div class="album-end-title flex justify-c">
            <img src="https://artimg2.artart.cn/image/20211227/3ee361dd9c7ae4e055cb1630e993743e.png">
          </div>
          <div class="album-end-price"><span>{{ statistical.deal_price || 0 }}</span><span class="ft">{{ merchantInfo.fee_type_text }}</span></div>
          <div class="album-end-price-bottom ft28 fw500">
            <div class="textAlignC name-top line1">{{ albumData.venue.title }}</div>
            <div class="textAlignC name-bottom">{{ albumData.album_name }}</div>
          </div>
          <div class="album-end-bottom-show flex">
            <div class="l">
              <div>{{ endDate }}</div>
              <div>{{ endTime }}结拍</div>
            </div>
            <div class="flex-one flex align-items-c justify-b">
              <div class="item">
                <div class="num">{{ statistical.total_num || 0 }}件</div>
                <div class="txt ft24">本场拍品</div>
              </div>
              <div class="item">
                <div class="num">{{ statistical.deal_num || 0 }}件</div>
                <div class="txt ft24">成交</div>
              </div>
              <div class="item">
                <div class="num">{{ statistical.deal_rate || 0 }}%</div>
                <div class="txt ft24">成交率</div>
              </div>
            </div>
          </div>
        </div>
        <div class="album-end-copy flex align-items-c justify-c">
          <img src="https://artimg2.artart.cn/image/20211227/8e5e94c82eaab992d9b22d0102701d2d.png">
        </div>
      </div>
    </template>
    <div class="bid-view">
      <div
        class="bid-price flex align-items-c justify-b"
        v-if="userNumber && status != 3"
      >
        <div
          class="bid-price-view flex-one flex align-items-c"
          v-if="bidPriceList.length > 0"
        >
          <div
            class="jian flex align-items-c justify-c"
            @click="jianAction"
          >
            <img
              src="https://artimg2.artart.cn/image/20211225/7fa15eb57976f56ba7ed859396397321.png"
              v-if="priceIndex == -1"
            >
            <img
              src="https://artimg2.artart.cn/image/20211225/7065391ed8c584e0cd1e5dd41f4f9415.png"
              v-else
            >
          </div>
          <div
            class="num flex-one fw500 flex align-items-c justify-c"
            @click="bidAction"
            v-show="priceIndex > -1"
            style="position: relative;"
          >{{auctionDetail && auctionDetail.now_price ? '加 ' : '出价 '}}
            <van-swipe
              ref="vanSwiperBid"
              class="bid-price-list"
              :duration="200"
              :loop="false"
              :show-indicators="false"
              :vertical="true"
              :touchable="false"
              :stop-propagation="false"
            >
              <van-swipe-item
                v-for="(m, i) in bidPriceList"
                :key="`bid${i}`"
              >
                <div class="bid-p">{{ m.bidNextPriceCha }}</div>
              </van-swipe-item>
            </van-swipe>
          </div>
          <div
            class="num flex-one fw500 flex align-items-c justify-c"
            v-show="priceIndex == -1"
            style="background: #ccc;font-size: 32px;"
          >点击“+”开启出价</div>
          <div
            class="jia flex align-items-c justify-c"
            @click="jiaAction"
          >
            <img src="https://artimg2.artart.cn/image/20211225/b6d3a91e0030c8d09315860cab8aa302.png">
          </div>
        </div>
        <!-- <div class="bid-price-btn fw500">立即出价</div> -->
      </div>
      <div class="bid-bottom flex align-items-c">
        <div
          class="icon"
          style="position: relative;"
        >
          <img src="https://artimg2.artart.cn/image/20211225/3f4f9557d62ba27f2b5e39c1f67fdb89.png">
          <!-- <button
            class="hide-button"
            open-type="contact"
          ></button> -->
        </div>
        <div
          class="share"
          catchtap='goShare'
        >
          <img src="https://artimg2.artart.cn/image/20211225/93e8a1808bc3b1515ee82c21c58c8a64.png">
        </div>
        <div
          class="r-btn flex-one ft28 fw500"
          v-if="!userNumber && status != 3"
          @click="useNumberPlateAction"
        >办理号牌</div>
        <div
          class="r flex-one flex justify-e ft28 align-items-c"
          v-else-if="userNumber"
          catchtap="goPayBondAction"
        >
          <div
            class="txt"
            v-if="userNumber"
          >号牌 <span>{{userNumber}}</span></div>
          <template v-if="albumData.bond_type == 1">
            <div
              class="txt"
              v-if="userNumberType == '免保号牌'"
            >免保号牌</div>
            <div
              class="txt"
              v-else-if="albumData.bond_price == 0"
            >无保证金</div>
            <div
              class="btn ft28 fw500"
              v-else-if="isPayBond && status != 3"
            >缴纳保证金</div>
            <div
              class="txt"
              v-else-if="isPayBond != undefined"
            >已缴纳保证金</div>
          </template>
          <template v-else-if="albumData.bond_type == 2">
            <div
              class="txt"
              v-if="userNumberType == '免保号牌'"
            >免保号牌</div>
            <div
              class="txt"
              v-else-if="canBidPrice != null"
            >出价额度 <span>{{ formatPriceNumber(canBidPrice, 2) }}</span></div>
            <div
              class="btn ft28 fw500"
              @click="goRecharge"
            >充值</div>
          </template>
        </div>
      </div>
    </div>
    <AuctionList
      ref="auctionList"
      @showDetail="showDetail"
    />
    <AuctionDetailEL ref="auctionDetailEL" />
    <AuctionBidRecord ref="auctionBidRecordEL" />
    <Congratulations ref="CongratulationsEL" />
    <CongratulationsMy ref="CongratulationsMyEL" />
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ref, computed } from "vue";
import { Toast, Swipe, SwipeItem } from "vant";
import { formatPriceNumber, parseTime } from "@/utils/util";
import {
  userNumberPlateHandle,
  albumDetail,
  checkBondPay,
  albumStartAuctionInfo,
  auctionBidPrice,
  albumAuctionStatistical,
} from "@/api/auction";
import AuctionList from "./AuctionList.vue";
import AuctionDetailEL from "./AuctionDetail.vue";
import AuctionBidRecord from "./AuctionBidRecord.vue";
import Congratulations from "./Congratulations.vue";
import CongratulationsMy from "./CongratulationsMy.vue";

export default {
  components: {
    AuctionList,
    AuctionDetailEL,
    AuctionBidRecord,
    Congratulations,
    CongratulationsMy,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  setup() {
    const route = useRouter();
    const store = useStore();
    const auctionDetailEL = ref(null); // 拍品详情组件
    const CongratulationsEL = ref(null);
    const CongratulationsMyEL = ref(null);

    // 获取号牌
    const userNumber = computed(() => {
      return albumData.value.numberPlate && albumData.value.numberPlate.number
        ? albumData.value.numberPlate.number
        : "";
    });

    const merchantInfo = computed(() => {
      return store.getters.merchantInfo ? store.getters.merchantInfo : {};
    });

    // 获取号牌
    const userNumberType = computed(() => {
      return albumData.value.numberPlate &&
        albumData.value.numberPlate.number_type
        ? albumData.value.numberPlate.number_type
        : "";
    });

    const roomId = ref(""); // 房间id
    const albumId = ref(""); // 专场id
    const auctionDetail = ref({}); // 当前拍品
    const cjList = ref([]); // 出价记录
    const cjList1 = ref([]); // 出价记录

    const loadNowAuction = () => {
      // 获取当前拍品
      albumStartAuctionInfo({ album_id: albumId.value })
        .then((res) => {
          if (res.data) {
            auctionDetail.value = res.data;
            if (res.data.bid_num) {
              cjList.value = res.data.bid_record;
              cjList1.value = [res.data.bid_record[0]];
              setTimeout(() => {
                if (vanSwiper.value) {
                  vanSwiper.value.swipeTo(cjList1.value.length - 1);
                }
              }, 100);
            }
            initPriceRule(res.data);
            initPrice(res.data);
          }
        })
        .catch((message) => Toast(message));
    };

    const albumData = ref("");
    const isPayBond = ref(false);
    const status = ref(""); // 状态
    const statistical = ref({});
    const endDate = ref("");
    const endTime = ref([]);

    const loadAlbumData = () => {
      albumAuctionStatistical({
        album_id: albumId.value,
      })
        .then((res) => {
          statistical.value = res.data;
        })
        .catch((message) => Toast(message));
    };

    const loadAlbumDetail = () => {
      // 专场详情
      albumDetail(albumId.value)
        .then((res) => {
          const d = res.data;
          status.value = d.album_status;
          if (status.value == 3) {
            endDate.value = parseTime(d.album_end_time, "{y}年{m}月{d}日");
            endTime.value = parseTime(d.album_end_time, "{h}时{i}");
            loadAlbumData();
          }

          if (d.bond_type == 1 && d.bond_price) {
            checkAlbumBondPay();
          }
          albumData.value = d;
        })
        .catch((message) => Toast(message));
    };

    const checkAlbumBondPay = () => {
      // 检查是否缴纳保证金
      checkBondPay(albumId.value).then((res) => {
        isPayBond.value = res.data.is_pay;
      });
    };

    // 获取用户可出价额度
    const canBidPrice = computed(() => {
      return albumData.value.bond_type === 2
        ? (store.getters.account.balance || 0) * albumData.value.bond_bid_ratio
        : 0;
    });

    // 获取号牌
    const useNumberPlateAction = () => {
      userNumberPlateHandle({
        venue_id: albumData.value.venue_id,
      })
        .then((res) => {
          albumData.value.numberPlate = res.data;
        })
        .catch((message) => Toast(message));
    };

    const auctionList = ref(null); // 拍品列表组件
    const showListAction = () => {
      auctionList.value.changeStatue = 1;
      auctionList.value.finished = false;
      auctionList.value.loading = false;
      auctionList.value.list = [];
      auctionList.value.queryParams.album_id = albumData.value.album_id;
      auctionList.value.queryParams.page = 1;
      auctionList.value.total = 0;
      auctionList.value.show = true;
      auctionList.value.loadList();
      auctionList.value.loadAlbumData(albumData.value.album_id);
      // 查看拍品列表
      // auctionList.
    };

    let setSystemTimeout = null;

    const sockedMes = (d) => {
      // socket通知
      if (d.type === "auction_start") {
        // 确认起拍价
        Toast("拍品可以出价了");
        auctionDetail.value = d.data.auction_info;
        initPriceRule(d.data.auction_info);
        initPrice(d.data.auction_info);
      } else if (d.type === "auction_bid") {
        // 出价
        if (
          auctionDetail.value &&
          auctionDetail.value.product_id === d.data.id
        ) {
          auctionDetail.value.now_price = d.data.now_price;
          cjList.value = [d.data.bid_record].concat(cjList.value);
          cjList1.value.push(d.data.bid_record);
          setTimeout(() => {
            vanSwiper.value.swipeTo(cjList1.value.length - 1);
          }, 100);
          settingPrice(d.data.bid_record.bid_price, 1);
        }
      } else if (d.type === "auction_bid_proxy") {
        // 委托出价
        if (
          auctionDetail.value &&
          auctionDetail.value.product_id === d.data.id
        ) {
          auctionDetail.value.now_price = d.data.now_price;
          cjList.value = [d.data.bid_record_list[0]].concat(cjList.value);
          cjList1.value.push(d.data.bid_record_list[0]);
          setTimeout(() => {
            vanSwiper.value.swipeTo(cjList1.value.length - 1);
          }, 100);
          settingPrice(d.data.bid_record_list[0].bid_price, 1);
        }
      } else if (d.type === "auction_bid_del") {
        // 删除最高价
        if (
          auctionDetail.value &&
          auctionDetail.value.product_id === d.data.id
        ) {
          cjList.value = d.data.bid_list;
          cjList1.value = [d.data.bid_list[0]];
          auctionDetail.value.now_price = d.data.now_price;
          settingPrice(d.data.bid_list[0].bid_price, 1);
        }
      } else if (d.type === "auction_end" || d.type === "auction_revoke") {
        // 拍品结束
        if (d.data.next_auction) {
          // 有下一件拍品
          if (d.type === "auction_revoke") {
            albumData.value.auction_num = d.data.auction_count;
          }
          if (
            d.type === "auction_end" &&
            d.data.auction_info &&
            d.data.auction_info.auction_status > 4
          ) {
            const old = d.data.auction_info;
            if (old.userInfo.uid == uid.value) {
              CongratulationsMyEL.value.transaction = {
                uname: old.userInfo.nickname,
                lot: old.lot,
                price: formatPriceNumber(old.now_price, 2),
                order_id: old.order_id,
              };
              console.log(CongratulationsMyEL.value.transaction);
            } else {
              CongratulationsEL.value.transaction = {
                uname: old.userInfo.nickname,
                lot: old.lot,
                price: formatPriceNumber(old.now_price, 2),
              };
              setTimeout(() => {
                CongratulationsEL.value.transaction = null;
              }, 5000);
            }
          }
          cjList1.value = [];
          cjList.value = [];
          auctionDetail.value = d.data.next_auction;
          initPriceRule(d.data.next_auction);
          initPrice(d.data.next_auction);
        } else {
          loadAlbumDetail();
        }
      } else if (d.type === "auction_remake") {
        // 重拍
        setSystemAction("拍品重新开始拍卖");
        cjList1.value = [];
        cjList.value = [];
        auctionDetail.value = d.data.auction_info;
        initPriceRule(d.data.auction_info);
        initPrice(d.data.auction_info);
      } else if (d.type === "auction_bid_confirm") {
        // 确认网络出价
        if (cjList.value.length > 0) {
          for (let i = 0; i < cjList.value.length; i += 1) {
            const m = cjList.value[i];
            if (m.bid_id === d.data.bid_record.bid_id) {
              cjList.value[i].is_confirm = 1;
              break;
            }
          }
        }
      } else if (d.type === "auction_edit_bidRange") {
        // 切换竞价阶梯
        if (
          auctionDetail.value &&
          auctionDetail.value.product_id === d.data.auction_id
        ) {
          setSystemAction("加价幅度已修改");
          auctionDetail.value.bid_range_id = d.data.bidRange_info.id;
          auctionDetail.value.bidRange = d.data.bidRange_info;
          initPriceRule(auctionDetail.value);
          initPrice(auctionDetail.value);
        }
      } else if (d.type === "auction_notice") {
        // 系统通知
        if (Number(roomId.value) === d.group_id) {
          setSystemAction(d.data.msg);
        }
      }
    };
    const setSystemAction = (txt) => {
      systemList.value = [txt];
      if (setSystemTimeout) clearTimeout(setSystemTimeout);
      setSystemTimeout = setTimeout(() => {
        setSystemTimeout = null;
        systemList.value = [];
      }, 5000);
    };

    const vanSwiper = ref(null); // 出价swiper

    const bidPriceList = ref([]);
    const priceIndex = ref(-1);
    const lock = ref(false);
    const upBid = ref(null);

    const vanSwiperBid = ref(null);

    const priceData = ref([{ min: 0, max: "∞", price: 100000, type: 1 }]);

    const getPriceArea = (price) => {
      const data = priceData.value;
      let index = null;
      for (let i in data) {
        const m = data[i];
        if (price >= m.min && price < m.max) {
          index = i;
          break;
        }
      }
      if (index === null) {
        index = data.length - 1;
      }
      return data[index];
    };

    const settingPrice = (price, refresh) => {
      price = Number(price);
      const area = getPriceArea(price);
      let p = 0;
      switch (area.type) {
        case 1:
          p = price + area.price;
          break;
        case 2:
          p = getBidMoney258(price);
          break;
        case 3:
          p = getBidMoneyThree258(price);
          break;
        default:
          p = getBidMoney258(price);
          break;
      }

      // bidNextPrice.value = p;
      setPrice(
        {
          bidNextPrice: p,
          bidNextPriceCha: formatPriceNumber(
            p -
              (cjList.value[0]
                ? cjList.value[0].bid_price
                : Number(auctionDetail.value.now_price)),
            2
          ),
        },
        refresh
      );
    };
    const setPrice = (data, refresh) => {
      bidPriceList.value = refresh ? [data] : bidPriceList.value.concat([data]);
      if (priceIndex.value == -1) {
        lock.value = false;
      } else {
        priceIndex.value = bidPriceList.value.length - 1;
        if (vanSwiperBid.value) {
          vanSwiperBid.value.swipeTo(priceIndex.value);
        }
        if (upBid.value) clearTimeout(upBid.value);
        upBid.value = setTimeout(() => {
          lock.value = false;
        }, 300);
      }
    };
    const getBidMoney258 = (price) => {
      const money = price.toString();
      let numCount = money.length;

      let firstNum = Number(money.substr(0, 1));
      let secondNum = money.substr(1, 1);

      if (numCount <= 2) {
        return 200;
      } else if (numCount <= 3) {
        if (firstNum < 2) {
          return 200;
        } else if (firstNum < 5) {
          return 500;
        } else if (firstNum < 8) {
          return 800;
        } else {
          return 1000;
        }
      }

      if (firstNum && secondNum == 0) {
        secondNum = 2;
      } else {
        if (secondNum < 2) {
          secondNum = 2;
        } else if (secondNum < 5) {
          secondNum = 5;
        } else if (secondNum < 8) {
          secondNum = 8;
        } else {
          firstNum += 1;
          secondNum = 0;
          if (`${firstNum}`.length > 1) {
            numCount += `${firstNum}`.length - 1;
          }
        }
      }
      return Number(prefixZero(`${firstNum}${secondNum}`, numCount));
    };
    const getBidMoneyThree258 = (price) => {
      const money = price.toString();
      const numCount = money.length;
      if (numCount < 5) {
        return getBidMoney258(price);
      }

      let firstNum = Number(money.substr(0, 1));
      let secondNum = money.substr(1, 1);
      let threeNum = money.substr(2, 1);

      if (threeNum < 2) {
        threeNum = Number(prefixZero(2, numCount - 2));
      } else if (threeNum < 5) {
        threeNum = Number(prefixZero(5, numCount - 2));
      } else if (threeNum < 8) {
        threeNum = Number(prefixZero(8, numCount - 2));
      } else {
        threeNum = Number(prefixZero(1, numCount - 1));
      }

      return Number(prefixZero(`${firstNum}${secondNum}`, numCount)) + threeNum;
    };
    const prefixZero = (num, n) => {
      return (num + Array(n).join(0)).slice(0, n);
    };

    const initPriceRule = (newVal) => {
      if (newVal.bid_range_id) {
        if (newVal.bidRange) {
          priceData.value = newVal.bidRange.content;
        }
      } else {
        priceData.value = [
          { min: 0, max: "∞", price: Number(newVal.plus_price), type: 1 },
        ];
      }
    };
    const initPrice = (newVal) => {
      if (newVal && newVal.product_id) {
        if (cjList.value[0] && cjList.value[0].bid_price) {
          settingPrice(cjList.value[0].bid_price, 1);
        } else if (newVal.now_price) {
          settingPrice(newVal.now_price, 2);
        } else {
          if (upBid.value) clearTimeout(upBid.value);
          lock.value = true;
          if (newVal.start_price) {
            setPrice(
              {
                bidNextPrice: Number(newVal.start_price),
                bidNextPriceCha: formatPriceNumber(
                  Number(newVal.start_price) - Number(newVal.now_price),
                  2
                ),
              },
              1
            );
          } else {
            settingPrice(0, 1);
          }
        }
      }
    };

    const jiaAction = () => {
      if (lock.value) return false;
      lock.value = true;
      if (priceIndex.value + 1 >= bidPriceList.value.length) {
        settingPrice(bidPriceList.value[priceIndex.value].bidNextPrice);
      } else {
        priceIndex.value = priceIndex.value + 1;
        vanSwiperBid.value.swipeTo(priceIndex.value);
        if (upBid.value) clearTimeout(upBid.value);
        upBid.value = setTimeout(() => {
          lock.value = false;
        }, 300);
      }
    };

    const jianAction = () => {
      if (priceIndex.value > -1) {
        if (lock.value) return false;
        lock.value = true;
        priceIndex.value = priceIndex.value - 1;
        vanSwiperBid.value.swipeTo(priceIndex.value);
        if (upBid.value) clearTimeout(upBid.value);
        upBid.value = setTimeout(() => {
          lock.value = false;
        }, 300);
      }
    };

    const showDetailAction = () => {
      if (auctionDetailEL.value) {
        auctionDetailEL.value.loadInit(auctionDetail.value.product_id);
      }
    };

    const showDetail = (id) => {
      if (auctionDetailEL.value) {
        auctionDetailEL.value.loadInit(id);
      }
    };

    const systemList = ref([]); // 系统通知

    // 获取用户uid
    const uid = computed(() => {
      return store.getters.uid;
    });

    const kong = () => {};

    let bidLock = false;
    const bidAction = () => {
      // 出价
      if (bidLock) return false;
      bidLock = true;
      const money_bid = bidPriceList.value[priceIndex.value].bidNextPrice;
      const auction_id = auctionDetail.value.product_id;

      auctionBidPrice({
        bid_price: money_bid,
        auction_id: auction_id,
        is_anonymous: 1
      })
        .then(() => {
          bidLock = false;
        })
        .catch((message) => {
          Toast(message);
          bidLock = false;
        });
    };

    const goRecharge = () => {
      route.push("/users/recharge");
    };

    const auctionBidRecordEL = ref(null);
    const showRecodeAction = () => {
      // 打开出价记录
      auctionBidRecordEL.value.finished = false;
      auctionBidRecordEL.value.loading = false;
      auctionBidRecordEL.value.list = [];
      auctionBidRecordEL.value.queryParams.auction_id =
        auctionDetail.value.product_id;
      auctionBidRecordEL.value.queryParams.page = 1;
      auctionBidRecordEL.value.total = 0;
      auctionBidRecordEL.value.show = true;
      auctionBidRecordEL.value.loadList();
    };

    return {
      systemList,
      uid,
      roomId,

      auctionDetailEL,
      status,
      formatPriceNumber,
      bidPriceList,
      priceIndex,
      albumId,
      albumData,
      isPayBond,
      auctionDetail,
      cjList,
      cjList1,

      userNumber,
      userNumberType,
      canBidPrice,
      auctionList,
      vanSwiper,
      vanSwiperBid,
      auctionBidRecordEL,
      merchantInfo,

      CongratulationsEL,
      CongratulationsMyEL,
      statistical,
      endDate,
      endTime,

      loadNowAuction,
      loadAlbumDetail,
      sockedMes,
      useNumberPlateAction,
      showListAction,
      jiaAction,
      jianAction,
      kong,
      showDetailAction,
      showDetail,
      bidAction,
      goRecharge,
      showRecodeAction,
    };
  },
};
</script>

<style lang="scss" scoped>
.auction-title img {
  width: 32px;
  height: 32px;
  display: block;
  position: relative;
  bottom: -2px;
}

.auction-title {
  padding: 0 32px 0 32px;
  font-size: 32px;

  &.padding {
    padding: 26px 32px;
  }

  .notice {
    width: 686px;
    background: #fcf4dd;
    border-radius: 8px;
    border: 2px solid #ece0bd;
    box-sizing: border-box;
    color: #875d2e;
    padding: 0 14px 0 0;
    position: absolute;
    height: 60px;
    left: 32px;
    top: -70px;
    transition: top 0.5s;
    -moz-transition: top 0.5s;
    /* Firefox 4 */
    -webkit-transition: top 0.5s;
    /* Safari 和 Chrome */
    -o-transition: top 0.5s;
    /* Opera */

    &.show {
      top: 10px;
    }
  }

  .notice img {
    width: 32px;
    height: 32px;
    margin: 0 8px 0 16px;
  }
}

.auction-content {
  padding: 34px 32px 0 32px;
}

.auction-img {
  width: 300px;
  height: 300px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
}

.auction-img img {
  width: 300px;
  height: 300px;
}

.auction-img-bg {
  position: absolute;
  bottom: 0;
  height: 50px;
  left: 0;
  width: 300px;
  background: linear-gradient(rgba(255, 255, 255, 0) 0%, #000000 100%);
}

.auction-img-txt {
  position: absolute;
  bottom: 0;
  height: 40px;
  right: 0;
  width: 80px;
  background: rgba(0, 0, 0, 0.4);
  border-top-left-radius: 8px;
}

.auction-img-txt img {
  width: 32px;
  height: 32px;
}

.auction-price-top {
  width: 356px;
  height: 216px;
  border-radius: 8px;
  border: 2px solid #f7e4ca;
  background: linear-gradient(146deg, #fff7ee 0%, #ffffff 100%);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
  position: relative;
  z-index: 100;

  .zhanwei {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 101;
  }
}

.auction-price-top.pos {
  position: absolute;
  right: 48px;
  bottom: 210px;
}

.my-has-bid {
  width: 356px;
  height: 100px;
  background: linear-gradient(180deg, #b31e24 0%, #b31e24 100%);
  border-radius: 8px;
  font-size: 32px;
  font-weight: 600;
  line-height: 100px;
  text-align: center;
  position: absolute;
  right: 48px;
  bottom: 94px;
  color: #fff;
  z-index: 100;
}

.auction-price-top .t {
  padding: 18px 24px 0 24px;
  height: 58px;
  box-sizing: border-box;
}

.auction-price-top .tl {
  color: #4f545a;
}

.auction-price-top .tr {
  color: #b31e24;
}

.auction-price-top .c {
  color: #c1955c;
  font-size: 60px;
  padding: 8px 0 0 24px;
  font-family: NUM;
  font-weight: 500;
  height: 78px;
  line-height: 70px;
  box-sizing: border-box;
  position: relative;
}

.auction-price-top .c .scroll-view {
  height: 70px;
  width: 100%;
}

.auction-price-top .c .scroll-view div {
  font-family: NUM;
  height: 70px;
  line-height: 70px;
}

.auction-price-top .b {
  padding-left: 24px;
  padding-top: 8px;
  font-size: 32px;
  font-weight: 500;
  color: #4f545a;
  box-sizing: border-box;
  height: 56px;
}

.auction-price-top .b .b-image {
  width: 48px;
  height: 48px;
  margin-right: 8px;
}

.auction-price-bottom {
  width: 316px;
  height: 92px;
  background: #f7f7f7;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  border: 2px solid #eeeeee;
  margin-top: -20px;
  margin-left: 20px;
}

.auction-price-bottom .c {
  padding: 0 28px 0 24px;
  margin-top: 20px;
}

.auction-price-bottom .c .c-image {
  width: 48px;
  height: 48px;
}

.auction-price-bottom .c .c-zw-image {
  width: 246px;
  height: 22px;
  margin-left: 10px;
}

.auction-price-bottom .c .c-r {
  color: #4f545a;
  font-family: NUM;
}

.album-end {
  margin: 24px 28px;
  border-radius: 16px;
  padding: 20px 20px 0 20px;
  background: linear-gradient(180deg, #fce4c8 0%, #edba8a 100%);
  box-shadow: 0px 4px 8px 0px rgba(176, 114, 56, 0.2);
}

.album-end-border {
  border-radius: 16px;
  border: 2px solid #fff9e7;
  box-shadow: 0px 4px 8px 0px rgba(176, 114, 56, 0.2);
}

.album-end-title img {
  width: 564px;
  height: 88px;
}

.album-end-price {
  font-size: 96px;
  text-align: center;
  color: #b65535;
  font-family: NUM;
}

.album-end-price span {
  text-shadow: 0 2px 2px #fff5cd;
}

.album-end-price .ft {
  font-size: 48px;
  font-weight: 500;
}

.album-end-price-bottom {
  padding: 0 60px;
  margin-top: 10px;
}

.album-end-price-bottom .item {
  color: #b65535;
  text-align: center;
  font-size: 36px;
  text-shadow: 0px 2px 2px #fff5cd;
}

.name-top {
  font-size: 32px;
  color: #b65535;
  text-shadow: 0px 2px 2px #fff5cd;
}
.name-bottom {
  margin-top: 16px;
  font-size: 28px;
  color: #b65535;
  text-shadow: 0px 2px 2px #fff5cd;
}

.album-end-bottom-show {
  margin-top: 34px;
  background: linear-gradient(90deg, #fff5e9 0%, #ffe3bf 100%);
  box-shadow: 0px 4px 8px 0px rgba(176, 114, 56, 0.2), 0px -2px 0px 0px #ffffff;
  border-radius: 0px 0px 16px 16px;
  padding: 30px 30px 30px 30px;
  text-align: center;
}
.album-end-bottom-show .l {
  margin-right: 36px;
  padding-right: 36px;
  border-right: 2px solid #f0d8b7;
}
.album-end-bottom-show div {
  font-size: 24px;
  font-weight: 500;
  color: #b65535;
  line-height: 34px;
  text-shadow: 0px 2px 2px #fff5cd;
}
.album-end-bottom-show .txt {
  font-size: 20px;
  font-weight: 400;
  margin-top: 5px;
}
.album-end-bottom-show .num {
  font-size: 28px;
}

.album-end-copy {
  height: 64px;
}

.album-end-copy img {
  width: 222px;
  height: 32px;
}

.bid-view {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: #fff;
}

.bid-bottom {
  height: 80px;
  padding: 18px 0;
  box-shadow: 0 -2px 0 0 rgba(0, 0, 0, 0.03);
  padding-bottom: 50px;
}

.r-btn {
  height: 80px;
  line-height: 80px;
  text-align: center;
  border-radius: 8px;
  background: #b31e24;
  color: #fff;
  margin: 0 20px 0 50px;
}

.bid-bottom img {
  width: 48px;
  height: 48px;
  display: block;
}

.bid-bottom .icon,
.bid-bottom .share {
  margin-left: 32px;
}

.bid-bottom .r {
  color: #666;
  padding-right: 24px;
}

.bid-bottom .r span {
  font-size: 32px;
  font-weight: 500;
  margin-left: 8px;
  color: #000;
  font-family: NUM;
}

.bid-bottom .r div {
  margin-right: 24px;
}

.bid-bottom .r .btn {
  color: #b31e24;
}

.bid-price {
  padding: 0 32px 34px 32px;
}

.bid-price-view {
  box-sizing: border-box;
  height: 100px;
  background: #ffffff;
}

.bid-price-view .jia,
.bid-price-view .jian {
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  border-radius: 8px;
  border: 2px solid #dddddd;
}

.bid-price-view img {
  width: 48px;
  height: 48px;
}

.bid-price-view .num {
  text-align: center;
  color: #b31e24;
  font-size: 40px;
  background: linear-gradient(180deg, #b31e24 0%, #b31e24 100%);
  border-radius: 8px;
  height: 100px;
  margin: 0 18px;
  color: #fff;
  line-height: 100px;
}

.bid-price-view .num .bid-p {
  font-family: NUM;
}

.bid-price-btn {
  width: 220px;
  height: 100px;
  background: linear-gradient(180deg, #b31e24 0%, #b31e24 100%);
  border-radius: 8px;
  text-align: center;
  line-height: 100px;
  font-size: 32px;
  color: #fff;
}

.bid-price-list {
  height: 100px;
  margin-left: 10px;
}
.bid-price-list1 {
  height: 75px;
}
</style>
